import React, { useState, useEffect } from "react";

import { getRandomGroup } from "../../helpers/whatsapp";
import "./Confirmation.scss";

const Confirmation = () => {
  const [whatsUrl, setWhatsUrl] = useState(null);

  useEffect(() => {
    const whatsAppUrl = getRandomGroup();
    setWhatsUrl(whatsAppUrl);
  }, []);

  return (
    <div className="confirmation">
      <h1>Próximos passos</h1>
      <p>
        Siga as instruções abaixo abaixo para saber quais são os próximos passos
      </p>

      <ol>
        <li>
          <span>Confirme seu e-mail</span>
          <p>
            Vá no seu e-mail e procure por&nbsp;
            <span className="highlight">
              “[Confirme seu email] Emerson Broga Dev”
            </span>
            &nbsp; para confirmar seu cadastro.
          </p>
          <div className="buttons">
            <a
              href="https://mail.google.com/mail/u/0/#search/is%3Aunread+emersonbroga.com"
              className="btn gmail"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                loading="lazy"
                className="icon gmail"
                src="/images/icon/iconfinder_5303_-_Gmail_1313515.png"
                alt="Gmail"
              />
              <span>Gmail</span>
            </a>
            <a
              href="https://login.yahoo.com/"
              className="btn yahoo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                loading="lazy"
                className="icon yahoo"
                src="/images/icon/iconfinder_26_social_2609559.png"
                alt="Yahoo"
              />
              <span>Yahoo</span>
            </a>
            <a
              href="https://login.live.com/"
              className="btn outlook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                loading="lazy"
                className="icon outlook"
                src="/images/icon/iconfinder_outlook_1175610.png"
                alt="Outlook"
              />
              <span>Outlook</span>
            </a>
          </div>
        </li>
        <li>
          <span>Seja avisado pelo WhatsApp</span>
          <p>
            Clique abaixo e cadastre-se para receber informações e conteúdo
            pelo&nbsp;<span className="highlight">WhatsApp</span>.
          </p>
          <div className="buttons">
            {!whatsUrl ? null : (
              <a
                href={whatsUrl}
                className="btn whatsapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  className="icon whatsapp"
                  src="/images/icon/iconfinder_Social-media_Whatsapp_4362952.png"
                  alt="Whatsapp"
                />
                <span>Ative seu WhatsApp</span>
              </a>
            )}
          </div>
        </li>
        <li>
          <span>Seja avisado pelo Telegram</span>
          <p>
            Clique abaixo e cadastre-se para receber informações e conteúdo
            no&nbsp;<span className="highlight">Telegram</span>.
          </p>
          <div className="buttons">
            <a href="https://t.me/jsdevils" className="btn telegram">
              <img
                loading="lazy"
                className="icon telegram"
                src="/images/icon/iconfinder_social-56_1591869.png"
                alt="Telegram"
                target="_blank"
                rel="noopener noreferrer"
              />
              <span>Ative seu Telegram</span>
            </a>
          </div>
        </li>
      </ol>

      <img
        loading="lazy"
        className="illustration"
        src="https://js.emersonbroga.com/static/illustrations/placeholders_rklh.svg"
        alt="O que você vai dominar com esse curso"
      />
    </div>
  );
};

export default Confirmation;
