import React from "react";
import Confirmation from "src/sections/Confirmation";
import SEO from "src/components/SEO";
import { NAME, BASE_URL } from "src/helpers/config";

const EmailConfirmation = () => {
  const seoProps = {
    title: `Confirme seu email - ${NAME}`,
    description:
      "Agora só falta mais um passo, confirme seu email, siga nas redes sociais e participe dos grupos de mensagens.",
    canonical: `${BASE_URL}/confirme-seu-email/`,
    image: `${BASE_URL}/meta/og-image-default.jpg`,
  };

  return (
    <div>
      <SEO {...seoProps} />
      <Confirmation />
    </div>
  );
};

export default EmailConfirmation;
